import axios from 'axios';
const axios2 = axios.create({});
// 添加请求拦截器
axios2.interceptors.request.use(
    (config) => {
      return config;
    },
    function(error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    },
  );
  // 添加响应拦截器

axios2.interceptors.response.use(
    function(response) {
      return response.data;
    },
    function(error) {
      // 对响应错误做点什么
      // localStorage.removeItem('token');
    //  //   重新去获取token
    //  router.replace({
    //   path: '/'
    // })
      
      return Promise.reject(error);
    },
  );
export default axios2;
