<template>
  <div>
    <!-- banner 开始 -->
    <div class="top">
      <swiper class="swiper-container" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in infos.banner" :key="index">
          <img
            :src="preSrc + item.pic"
            alt="banner"
            @click="goNext(item.link, item.target)"
          />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination">分页</div>
      </swiper>
      <div class="login-wrap">
        <!-- 登录 -->
        <div v-if="!hasToken">
          <div class="form-con">
            <ul class="flex">
              <li class="act">扫码登录</li>
            </ul>
            <div class="pic-wrap">
              <img class="wechat-pic" :src="wechatPic" alt="" />
              <div class="out-time" v-if="outTime">
                <p class="wechat-tip">二维码已过期</p>
                <button type="button" @click="goWechat" class="btn">
                  刷新
                </button>
              </div>
              <div class="out-time" v-if="hasCodePic">
                <div class="wechat-success">
                  <img src="@/assets/img/home/right.png" alt="" />
                </div>
                <p class="wechat-tip">扫码成功</p>
              </div>
            </div>
            <div class="wechat-wrap">
              <img src="@/assets/img/home/wechat.png" alt="" />
              请使用微信扫码
            </div>
          </div>
        </div>
        <!-- 个人信息 -->
        <div class="user-info" v-else>
          <div class="infos">
            <img
              class="avatar"
              :src="userInfo.headimg"
              alt=""
            />
            <div class="user">
              <p>Hi，{{ userInfo.nickname }}</p>
              <span>欢迎来到砸壳课堂</span>
            </div>
          </div>
          <ul class="course-game-enter">
            <li @click="toPages('bought-course')">
              <img src="@/assets/img/home/icon-game.png" alt="" />
              <span>我的课件</span>
            </li>
            <li @click="toPages('my-game')">
              <img src="@/assets/img/home/icon-course.png" alt="" />
              <span>我的游戏</span>
            </li>
          </ul>
          <button class="plain-btn" @click="handleExit">退出</button>
        </div>
      </div>
    </div>
    <!-- banner 结束 -->
    <!-- 忘记密码 -->
    <transition name="fade">
      <div
        class="mask-container"
        :class="[isShowMask ? 'mask-show' : 'mask-hide']"
        v-if="isShowMask"
      >
        <div class="mask-con">
          <p class="title">绑定手机</p>
          <el-input
            class="form-item"
            placeholder="请输入手机号"
            v-model="forget.phone"
          >
            <img
              slot="prefix"
              class="icon"
              v-if="icon[0] == 0"
              src="@/assets/img/home/icon1.png"
              alt=""
            />
            <img
              slot="prefix"
              class="icon"
              v-else
              src="@/assets/img/home/icon1-act.png"
              alt=""
            />
          </el-input>
          <el-input
            class="form-item"
            placeholder="请输入短信验证码"
            v-model="forget.code"
          >
            <img
              slot="prefix"
              class="icon"
              v-if="icon[1] == 0"
              src="@/assets/img/home/icon2.png"
              alt=""
            />
            <img
              slot="prefix"
              class="icon"
              v-else
              src="@/assets/img/home/icon2-act.png"
              alt=""
            />
            <span class="vertical-code" slot="suffix" v-if="popShowSecond"
              >{{ second }}s</span
            >
            <span
              v-else
              class="vertical-code"
              slot="suffix"
              @click="getForgetPhoneCode"
              >获取验证码</span
            >
          </el-input>
          <button class="tip-btn btn" @click="handleForgetPassword">
            提交
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import axios2 from "@/axios2";
export default {
  props: {
    cShowWork: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasToken: false, //是否有token
      preSrc: config.src,
      phone: "",
      userInfo: {},
      swiperOptions: {
        slidesPerView: 1, //一页展示滑动元素的个数
        // autoplay: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //点击分页器的指示点可控制swiper切换
        },
      },
      infos: {},
      icon: [0, 0, 0],
      isShowMask: false, //忘记密码遮罩
      outTime: false,
      ruleForm: {
        //注册的信息
        phone: "",
        code: "",
        password: "",
      },
      forget: {
        //忘记密码
        phone: "",
        code: "",
        password: "",
      },
      second: 60,
      wechatPic: "",
      timer: null,
      popCodeTimer: null,
      wechatId: "",
      hasCodePic: false,
      popShowSecond: false,
      relation_code: "",
    };
  },
  created() {
    let _self = this;
    _self.initData();
    let token = localStorage.getItem("token");
    if (!token) {
      _self.goWechat();
    }
  },
  methods: {
    // 初始化
    initData() {
      let _self = this;
      axios2.post(config.url.home).then((res) => {
        if (res.code == 200) {
          _self.infos = res.data;
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
      let token = localStorage.getItem("token");
      token ? (_self.hasToken = true) : (_self.hasToken = false);
      if (token) {
        _self.hasToken = true;
        _self.axios.post(config.url.userInfo).then((res) => {
          if (res.code == 200) {
            _self.userInfo = res.data;
            // _self.phone =
            //   res.data.phone.substr(0, 3) + "****" + res.data.phone.substr(7);
          } else if (res.code == 401) {
            _self.hasToken = false;
          } else {
            _self.showTip("error", res.msg);
          }
        });
      } else {
        _self.hasToken = false;
      }
    },
    // 忘记密码手机验证码
    getForgetPhoneCode() {
      let _self = this,
        form = _self.forget;
      let regTel = /^1[3456789]\d{9}$/;
      if (!regTel.test(form.phone)) {
        _self.showTip("error", "请输入正确的手机号");
        return;
      }
      _self.axios
        .post(config.url.mpLoginSMS, _self.$qs.stringify({ phone: form.phone }))
        .then((res) => {
          if (res.code == 200) {
            _self.popShowSecond = true;
            if (!_self.popCodeTimer) {
              _self.popCodeTimer = setInterval(() => {
                _self.second--;
                if (_self.second <= 0) {
                  clearInterval(_self.popCodeTimer);
                  _self.second = 60;
                  _self.popShowSecond = false;
                }
              }, 1000);
            }
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 展示弹窗
    showMask() {
      let _self = this;
      _self.isShowMask = true;
    },
    // 提交忘记密码
    handleForgetPassword() {
      let _self = this,
        form = _self.forget;
      let regTel = /^1[3456789]\d{9}$/;
      if (!form.phone || !regTel.test(form.phone)) {
        _self.showTip("error", "请输入正确的手机号");
        return;
      }
      if (!form.code || form.code.length != 6) {
        _self.showTip("error", "请输入短信验证码");
        return;
      }
      let temp = {
        phone: form.phone,
        code: form.code,
        relation_code: _self.relation_code,
      };
      _self.axios
        .post(config.url.bindPhoneV2, _self.$qs.stringify(temp))
        .then((res) => {
          if (res.code == 200) {
            _self.isShowMask = false;
            _self.showTip("success", res.msg);
            localStorage.setItem("token", res.data.token);
            _self.initData();
            _self.$emit("changeShowWork", true);
            window.location.reload();
            clearInterval(_self.popCodeTimer);
            _self.second = 60;
            _self.popShowSecond = false;
            _self.hasCodePic = false;
            return;
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 退出
    handleExit() {
      let _self = this;
      localStorage.clear();
      _self.initData();
      _self.$emit("changeShowWork", false);
      window.location.href = "/";
    },
    toPages(val) {
      let _self = this;
      _self.$router.push({
        name: val,
      });
    },
    goNext(link, target) {
      if (target != 1) {
        window.open(link);
      } else {
        window.location.href = link;
      }
    },
    goWechat() {
      let _self = this;
      _self.axios.post(config.url.getCodePic).then((res) => {
        if (res.code == 200) {
          _self.wechatPic = "data:image/jpeg;base64," + res.data.qrcode;
          _self.wechatId = res.data.id;
          _self.outTime = false;
          _self.timer = null;
          _self.getCodeNext(res.data.id);
        } else if (res.code != 200 && res.code != 401) {
          _self.showTip("error", res.msg);
        }
      });
    },
    // 扫描二维码后
    getCodeNext(id) {
      let _self = this;
      let timeCount = 150;
      if (!_self.timer) {
        _self.timer = setInterval(() => {
          timeCount--;
          _self.axios
            .post(
              config.url.getCodeResult,
              _self.$qs.stringify({
                id: id,
              })
            )
            .then((res) => {
              if (res.code == 200) {
                if (res.data.status == 1 && res.data.have_phone == 1) {
                  clearInterval(this.timer);
                  _self.hasCodePic = true;
                  setTimeout(() => {
                    localStorage.setItem("token", res.data.token);
                    _self.initData();
                    _self.$emit("changeShowWork", true);
                    window.location.reload();
                    _self.hasCodePic = false;
                  }, 1000);
                  return;
                } else if (res.data.status == 1 && res.data.have_phone == 2) {
                  clearInterval(this.timer);
                  _self.hasCodePic = true;
                  _self.relation_code = res.data.relation_code;
                  _self.isShowMask = true;
                } else {
                  return;
                }
              } else if (res.code != 200 && res.code != 401) {
                _self.showTip("error", res.msg);
              }
            });
          if (timeCount <= 0) {
            clearInterval(_self.timer);
            _self.timer = null;
            _self.outTime = true;
            timeCount = 150;
          }
        }, 2000);
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.popCodeTimer);
  },
};
</script>
<style lang="scss" scoped>
.top {
  max-width: 1920px;
  position: relative;
  .swiper-container {
    max-width: 1920px;
    height: 460px;
    .swiper-slide {
      width: 100%;
      text-align: center;
      img {
        height: 460px;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
        display: inline-block;
      }
    }
  }
  .login-wrap {
    position: absolute;
    height: 460px;
    left: 50%;
    top: 0;
    margin-left: -600px;
    width: 1200px;
  }
  .form-con {
    position: absolute;
    width: 320px;
    height: 415px;
    padding: 20px 30px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
    border-radius: $border-radius-main;
    top: 20px;
    right: 0;
    z-index: 2;
    ul {
      display: flex;
      justify-content: space-around;
      font-size: 20px;
      color: $font-color-81;
      margin-bottom: 20px;
      li {
        letter-spacing: 2px;
        flex: 1;
        text-align: center;
        cursor: pointer;
      }
      .act {
        color: $color-main;
      }
      li::after {
        margin: 10px auto 0;
        display: block;
        content: "";
        width: 50px;
        height: 6px;
        background: #fff;
        border-radius: 10px;
      }
      li.act::after {
        background: $color-main;
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
      }
    }
    .form-item {
      margin-bottom: 20px;
    }
    .wechat-title {
      text-align: center;
      margin-bottom: 30px;
    }
    .pic-wrap {
      position: relative;
      width: 240px;
      height: 240px;
      margin: 30px auto;
      .out-time {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #fff, $alpha: 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .wechat-success {
          width: 60px;
          height: 60px;
          margin-bottom: 10px;
          img {
            width: 100%;
          }
        }
        .btn {
          width: 120px;
          height: 36px;
          font-size: 14px;
          margin: 10px 0;
        }
      }
    }
    .wechat-pic {
      width: 240px;
      margin: 30px auto;
    }
    .wechat-tip {
      text-align: center;
      font-size: 14px;
    }
    .phone-btn {
      color: $font-color-9f;
      cursor: pointer;
    }
  }

  .forget {
    width: 100%;
    text-align: right;
    font-size: $font-size-main;
    margin-bottom: 20px;
    span {
      color: $color-main;
      cursor: pointer;
    }
  }
  .login-btn {
    margin: 0 auto;
  }
  .to-register {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    color: $font-color-9f;
    span {
      color: $color-main;
      cursor: pointer;
    }
  }
  .wechat-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $font-color-9f;
    margin-top: 20px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      margin-right: 6px;
    }
  }
}
.vertical-code {
  color: $color-main;
  cursor: pointer;
}
.icon {
  width: 14px;
  height: 16px;
  object-fit: contain;
}
// 弹窗
.mask-container {
  .title {
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .mask-bg {
    background: rgba(0, 0, 0, 0.5);
  }
  .mask-con {
    width: 600px;
    height: 400px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: $border-radius-main;
    // text-align: center;
    margin: 0 auto;
    .tip-btn {
      width: 260px;
      margin: 0 auto;
    }
  }
}
.el-input {
  width: 260px !important;
  margin: 0 auto 20px;
  display: block;
}
.user-info {
  position: absolute;
  width: 320px;
  height: 415px;
  padding: 20px 30px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.16);
  border-radius: $border-radius-main;
  top: 20px;
  right: 0;
  z-index: 2;
  font-size: 20px;
  .infos {
    display: flex;
    align-items: center;
    margin-top: 10px;
    .avatar {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 30px;
    }
    .user {
      p {
        margin-bottom: 15px;
        color: $font-color-main;
      }
      span {
        font-size: 18px;
        color: $font-color-9f;
      }
    }
  }
  ul.course-game-enter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    li {
      flex-shrink: 0;
      flex-grow: 0;
      text-align: center;
      cursor: pointer;
      img {
        width: 96px;
        height: 96px;
        margin-bottom: 10px;
        border-radius: 0;
      }
      span {
        color: $font-color-81;
      }
    }
    li:last-of-type {
      margin-right: 0;
    }
  }
  .plain-btn {
    width: 240px;
    height: 50px;
    line-height: 50px;
    margin: 30px 0 0 10px;
    box-sizing: border-box;
    border-radius: 30px;
    border: 1px solid $color-main;
    background: #fff;
    color: $color-main;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
