<template>
  <BaseLoading v-if="loading" />
  <div class="container wrapper" v-else>
    <div class="content">
      <BaseTop />
      <div class="con">
        <ul class="list">
          <li
            class="flex item"
            v-for="(item, index) in list"
            :key="item.course_id"
            @click.prevent="toCourseInfos(item.course_id)"
          >
            <div class="item-l">
              <img :src="preSrc + item.cover" alt="" />
            </div>
            <div class="item-r">
              <h3 class="ellipsis" :title="item.name">{{ item.name }}</h3>
              <p class="desc">{{ item.desc }}</p>
              <div class="item-r-b between">
                <div>
                  <span class="price">￥{{ item.price }}</span>
                  <del class="del-price">{{ item.original_price }}</del>
                </div>
                <div class="flex">
                  <div class="flex favor-work">
                    <CourseCancelFavor
                      v-if="item.collect_status == 1"
                      :cId="item.course_id"
                      :cStatus="2"
                      :cType="1"
                      :cIndex="index"
                      :cItem="item"
                      @favorCancel="handleFavorCanel"
                    />
                    <CourseFavor
                      v-else
                      :cId="item.course_id"
                      :cStatus="1"
                      :cType="1"
                      :cIndex="index"
                      :cItem="item"
                      @favorStatus="handleFavor"
                    />

                    <span class="slash">/</span>
                    <WorkbenchCancel
                      v-if="item.issue_status == 1"
                      :cType="1"
                      :cId="item.course_id"
                      :cStatus="2"
                      :cItem="item"
                      :cIndex="index"
                      @workbenchCancel="workbenchCancel"
                    />
                    <Workbench
                      v-else
                      :cType="1"
                      :cId="item.course_id"
                      :cTitle="item.name"
                      :cStatus="1"
                      :cItem="item"
                      :cIndex="index"
                      @handleWorkbench="handleWorkbench"
                    />
                  </div>
                  <button class="btn" v-if="item.buy_status == 1">
                    <i class="iconfont icon-duihao"></i> 已购买
                  </button>
                  <button
                    class="btn"
                    v-else
                    @click.stop="toPurchase(item.course_id, 2)"
                  >
                    立即购买
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!-- 分页 -->
        <div class="pagination-con">
          <el-pagination
            :page-size="pageSize"
            :pager-count="5"
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <!-- 分页end -->
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>

<script>
import BaseTop from "@/components/top/top.vue";
import BaseLoading from "@/components/loading/BaseLoading.vue";
export default {
  components: {
    BaseTop,
    BaseLoading,
  },
  data() {
    return {
      preSrc: config.src,
      list: [],
      total: 0,
      pageSize: 10,
      loading: true,
    };
  },
  created() {
    let _self = this;
    _self.initData(1);
  },
  methods: {
    initData(page) {
      let _self = this;
      _self.axios
        .post(config.url.courseList, _self.$qs.stringify({ page, size: 10 }))
        .then((res) => {
          if (res.code == 200) {
            _self.list = res.data.list;
            _self.total = res.data.total;
            setTimeout(() => {
              _self.loading = false;
            }, 100);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 改变分页
    handleCurrentChange(page) {
      let _self = this;
      _self.initData(page);
    },
    // 取消收藏
    handleFavorCanel(status, index, item) {
      let _self = this;
      item.collect_status = status;
      _self.$set(_self.list, index, item);
    },
    // 收藏
    handleFavor(status, index, item) {
      let _self = this;
      item.collect_status = status;
      _self.$set(_self.list, index, item);
    },
    // 发布到工作台
    handleWorkbench(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 取消发布到工作台
    workbenchCancel(status, index, item) {
      let _self = this;
      item.issue_status = status;
      _self.$set(_self.list, index, item);
    },
    // 到详情
    toCourseInfos(id) {
      let _self = this;
      _self.$router.push({
        name: "course-info",
        params: {
          courseId: id,
        },
      });
    },
    // 选择课程
    handleSubject(id) {},
    toPurchase(id, type) {
      let _self = this;
      _self.$router.push({
        name: "purchase",
        params: {
          id: id,
          type: type,
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.con {
  .item {
    margin-top: 60px;
    background: #fff;
    box-sizing: border-box;
    .item-l {
      width: 360px;
      height: 240px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      overflow: hidden;
      img {
        width: 360px;
      height: 240px;
      object-fit: contain;
      }
    }
    .item-r {
      width: 840px;
      height: 240px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 30px;
      box-sizing: border-box;
      border: 1px solid #efefef;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 18px;
        color: $font-color-51;
        line-height: 30px;
        height: 60px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }
      .price {
        font-size: 30px;
        color: $color-main;
        margin-right: 20px;
        font-weight: bold;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .favor-work {
        color: $font-color-9f;
        margin-right: 30px;
      }
      .slash {
        margin: 0 15px;
      }
      .icon-duihao {
        margin-right: 5px;
      }
    }
  }
  .item:hover {
    cursor: pointer;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.05);
    .item-r h3 {
      color: $color-main;
    }
  }
}
</style>
